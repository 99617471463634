@import "variables.scss";

@import "bootstrap-overwrites/buttons.scss";
@import "bootstrap-overwrites/toolbar.scss";
@import "bootstrap-overwrites/badges.scss";

html, body { height: 100%; }
body { margin: 0; font-family: Arial, "Helvetica Neue", sans-serif; }

.container {
  max-width: 80vw;
}

.toolbar-item-spacer {
  flex: 1 1 auto;
}

.login-logo {
  background-color: $afbim-violet;
  height: 100vh;
}

.login-logo-image {
  height: 100%;
  width: 100%;
}

.login-form {
  padding-top: 15vw;
  padding-left: 5vw;
  padding-right: 5vw;
}

.login-spinner {
  padding-left: 1vw;
}

.mat-mdc-card {
  padding: 5px;
}

.jumbotron {
  font-size: 50px;
}

.annotation {
  width: 30px;
  height: 30px;
  background-color: #3498db;
  border: 1px solid gray;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-card {
  position: relative;
  padding: 20px;
  background-color: $afbim-violet;
  border-radius: 5px;
  box-shadow: 0 1px 2.94px 0.06px #041a3729;
  border: 0;
  color: whitesmoke;
}

.dashboard-card .title h4 {
  font-weight: 700;
  font-size: 1.5rem;
}
