.badge-default {
  padding: 5px 8px;
  line-height: 12px;
  font-weight: 400;
  font-size: 13px;
  border: 1px solid $afbim-default;
  color: $afbim-default;
  border-radius: 5px;
}

.badge-success {
  padding: 5px 8px;
  line-height: 12px;
  font-weight: 400;
  font-size: 13px;
  border: 1px solid $afbim-success;
  color: $afbim-success;
  border-radius: 5px;
}

.badge-warning {
  padding: 5px 8px;
  line-height: 12px;
  font-weight: 400;
  font-size: 13px;
  border: 1px solid $afbim-warning;
  color: $afbim-warning;
  border-radius: 5px;
}

.badge-danger {
  padding: 5px 8px;
  line-height: 12px;
  font-weight: 400;
  font-size: 13px;
  border: 1px solid $afbim-danger;
  color: $afbim-danger;
  border-radius: 5px;
}