/*
    https: //mui.com/material-ui/customization/color/
*/

@import '@angular/material/theming';
@include mat-core();

$my-primary: mat-palette($mat-purple, 800);
$my-accent: mat-palette($mat-pink, 800);
$my-warn: mat-palette($mat-red, 800);

$my-theme: mat-light-theme($my-primary, $my-accent, $my-warn);

@include angular-material-theme($my-theme);
